import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image: FC<any> = ({ url, className, alt, localFile, opts = { loading: 'lazy' }, style, dimensions }) => {
    // if no localFile found in image then is previewing article, display regular image component
    if (!localFile?.childImageSharp) {
        return <img className={className} {...dimensions} alt={alt || ''} src={url} {...opts} />;
    }

    const imgSrc = getImage(localFile);
    return <GatsbyImage image={imgSrc} className={className} alt={alt || ''} {...opts} style={{ ...style }} />;
};

export default Image;
