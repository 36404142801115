import KATSUTA_IMG from '@images/authors/dr_katsuta.jpg';
import DSAJ_IMG from '@images/authors/dsaj.jpeg';
import KYOHEI_ISHIKAWA from '@images/authors/kyohei_ishikawa.jpeg';
import NAGATA_IMG from '@images/authors/nagata.jpeg';
import MAEZUMI_IMG from '@images/authors/nozomi-maezumi.png';
import SHUNSUKE_IMG from '@images/authors/shunsuke.jpg';
import AIKO_IMG from '@images/authors/yao.jpeg';
import YASUYOSHI_IMG from '@images/authors/yasuyoshi_matsuda.jpeg';
import YUKIYO_IMG from '@images/authors/yukiyo_otani.jpg';
import YUSUKE_IMG from '@images/authors/yusuke_kadota.jpeg';
import MASATO_IMG from '@images/interviewees/masato.jpg';
import RIKA_IMG from '@images/interviewees/rika.png';
import YAMAGUCHI_IMG from '@images/interviewees/yamaguchi.jpg';

export default {
    松田康良先生: {
        image: YASUYOSHI_IMG,
        url: null,
    },
    大谷幸代さん: {
        image: YUKIYO_IMG,
        url: null,
    },
    門田勇介先生: {
        image: YUSUKE_IMG,
        url: null,
    },
    山口大輝先生: {
        image: YAMAGUCHI_IMG,
        url: ['https://camec-ad.jp/', 'https://www.nihon-u.ac.jp/', 'https://jsvdi.com/'],
    },
    草刈亜以子さん: {
        image: AIKO_IMG,
        url: [
            null,
            'https://www.yokohamasupdogs.com/',
            'https://www.sankotsu.yokohama/',
            'https://sup-j.org/',
            'https://sup-j.org/instructors/level2/%e8%8d%89%e5%88%88%e4%ba%9c%e4%bb%a5%e5%ad%90%ef%bc%88aiko-kusakari%ef%bc%89/',
        ],
    },
    勝田誠重先生: {
        image: KATSUTA_IMG,
        url: ['https://camec-kd.jp/', 'https://www.rakuno.ac.jp/', 'https://www.jsvd.jp/', 'http://www.jvcs.jp/'],
    },
    日本ドッグサップ協会: {
        image: DSAJ_IMG,
        url: null,
    },
    阿片俊介主任動物看護士: {
        image: SHUNSUKE_IMG,
        url: ['https://camec-kd.jp/', 'https://www.nvlu.ac.jp/', 'https://www.ccrvn.jp/'],
    },
    伊藤雅人先生: {
        image: MASATO_IMG,
        url: [
            'https://camec-ad.jp/?utm_source=wantimes&utm_medium=blog&utm_campaign=drito',
            'https://www.jsvd.jp/',
            'https://www.saaivm.com/',
        ],
    },
    長田萌先生: {
        image: NAGATA_IMG,
        url: [
            'https://camec-ad.jp/?utm_source=wantimes&utm_medium=blog&utm_campaign=drnagata',
            'https://www.azabu-u.ac.jp/academic_graduate/veterinary/vet/',
            null,
            null,
            null,
            'https://camec-kd.jp/?utm_source=wantimes&utm_medium=blog&utm_campaign=drnagata',
        ],
    },
    石川恭平さん: {
        image: KYOHEI_ISHIKAWA,
        url: ['https://wiz-dog.com/'],
    },
    前住希望さん: {
        image: MAEZUMI_IMG,
        url: ['https://camec-az.jp/ts-blume'],
    },
    田中りかさん: {
        image: RIKA_IMG,
        url: ['https://www.autocamp.or.jp/'],
    },
} as { [key: string]: { image: string; url?: string[] | null } };
