import React, { FC } from 'react';
import classnames from 'classnames';

import TrackingLink from '@components/trackingLink';
import Image from '@components/imgComp';

import * as styles from './styles.module.scss';

type Props = {
    hero_img: {
        alt?: string;
        url: string;
        localFile?: any;
        dimensions: {
            width: number;
            height: number;
        };
    };
    title: {
        text: string;
    };
    uid: string;
    className?: string;
    tracking: {
        event: string;
        obj: {
            article_category: string;
            article_name: string;
            uid: string;
        };
    };
    isAmp?: boolean;
};

const NewPostCard: FC<Props> = ({ uid, hero_img, title, className, tracking, isAmp = false }) => (
    <div className={classnames(styles['new_card'], className)}>
        <TrackingLink href={`/${uid}/${isAmp ? 'amp/' : ''}`} tracking={tracking}>
            {isAmp ? (
                <amp-img
                    src={hero_img?.url}
                    alt={title?.text ?? ''}
                    width="190"
                    height="135"
                    layout="responsive"
                    style={{ objectFit: 'cover' }}
                />
            ) : (
                <Image {...hero_img} />
            )}
            <div className={styles['card_overlay']}>
                <span>{title?.text}</span>
            </div>
        </TrackingLink>
    </div>
);

export default NewPostCard;
