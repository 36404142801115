import React from 'react';
import * as styles from './styles.module.scss';
import LINE_ICON from '@assets/sns/line.svg';

const FixedCta: React.FunctionComponent = () => {
    return (
        <div className={styles['fixed_icon']}>
            <a
                className={styles['fixed_icon_link']}
                href="https://page.line.me/aoq7369a?openQrModal=true"
                target="_blank"
                rel="noreferrer"
                aria-label="Line Link"
            >
                <LINE_ICON />
            </a>
        </div>
    );
};

export default FixedCta;
