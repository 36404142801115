import { format, parseISO } from 'date-fns';

import jp from 'date-fns/locale/ja';

export const formateDate = (date: string): string => {
    if (!date) return '';
    return format(parseISO(date), 'PPP', { locale: jp });
};

export default formateDate;
