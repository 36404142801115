import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import authorData from '@config/authors';

const siteUrl = process.env.GATSBY_URL || 'http://localhost:8000/';

const Seo = ({
    href,
    title,
    author,
    category,
    date,
    description,
    meta_description,
    hero_img,
    image,
    url,

    isPost,

    uid,
    first_publication_date,
    last_publication_date,
}: any) => {
    const metaDescription = (stringLength: number) => {
        return meta_description?.text
            ? meta_description.text
            : description
            ? `${description?.slice(0, stringLength)}...`
            : title?.text
            ? title.text
            : title;
    };

    const BlogLdSeo = {
        '@context': 'https://schema.org',
        '@type': 'Article',
        description: metaDescription(20),
        url: `${siteUrl}${uid}`,
        name: title?.text,
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${siteUrl}${uid}`,
        },
        headline: title?.text,
        image: [hero_img?.url],
        datePublished: first_publication_date,
        dateModified: last_publication_date,
        author: [
            {
                '@type': 'Person',
                name: author,
                url: authorData?.[author]?.url?.[0] ?? siteUrl,
            },
        ],
        publisher: {
            '@type': 'Organization',
            name: 'Leo&Lea',
            logo: {
                '@type': 'ImageObject',
                url: `${siteUrl}meta_logo.jpg`,
            },
        },
    };

    return (
        <Helmet title={title?.text ?? title} defer={false} htmlAttributes={{ lang: 'en' }}>
            {/* general tags */}
            <title>{title?.text ?? title}</title>
            <meta name="description" content={metaDescription(20)} />

            {/* opengraph tags */}
            <meta property="og:url" content={href} />
            <meta property="og:image" content={hero_img?.url ?? `${url}${image}`} />
            <meta property="og:image:secure_url" content={hero_img?.url ?? `${url}${image}`} />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="400" />
            <meta property="og:title" content={title?.text ?? title} />
            {isPost && <meta property="og:type" content="article" />}
            <meta property="og:description" content={metaDescription(45)} />
            <meta property="og:locale" content="ja_JP" />
            <meta property="og:site_name" content="レオ＆レア" />

            {/* article tags */}
            {isPost && <meta property="article:publisher" content={author} />}
            {isPost && <meta property="article:section" content={category} />}
            {isPost && <meta property="article:published_time" content={date} />}

            {/* twitter tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:image" content={hero_img?.url ?? `${url}${image}`} />
            <meta name="twitter:creator" content="@leoandleajapan" />
            <meta name="twitter:site" content="@leoandleajapan" />
            <meta name="twitter:description" content={metaDescription(45)} />
            <meta name="twitter:title" content={title?.text ?? title} />

            {isPost && <script type="application/ld+json">{JSON.stringify(BlogLdSeo)}</script>}
        </Helmet>
    );
};

const seoWithQuery = (props: any) => (
    <StaticQuery
        query={graphql`
            query seoQuery {
                site {
                    siteMetadata {
                        description
                        author
                        title
                        image
                        url
                    }
                }
            }
        `}
        render={({ site: { siteMetadata } }) => {
            return <Seo {...siteMetadata} {...props} {...props.data} />;
        }}
    />
);

export default seoWithQuery;
