// extracted by mini-css-extract-plugin
export var brandColor = "#846d3d";
export var mainColor = "#2f4758";
export var subColor = "#c0d1dd";
export var lightColor = "#f4f6f8";
export var greyColor = "#707070";
export var darkColor = "#363636";
export var subscriptionColor = "#65c79b";
export var orderColor = "#298df3";
export var errorColor = "#fd3894";
export var frontlineColor = "#e08912";
export var categoryFoodColor = "#e08912";
export var categoryDogHealthColor = "#298df3";
export var categoryTrainingColor = "#65c79b";
export var categoryLifestyleColor = "#fd3188";
export var new_posts = "styles-module--new_posts--Vv_qX";
export var category_wrapper = "styles-module--category_wrapper--U5uox";
export var categories = "styles-module--categories--209Q1";
export var category__header = "styles-module--category__header--2CD3B";
export var category__cards = "styles-module--category__cards--2h_fn";
export var category__card = "styles-module--category__card--2nQxt";
export var category__banner = "styles-module--category__banner--1MDz4";
export var image = "styles-module--image--1V200";