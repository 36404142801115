import React from 'react';
import { useTranslation } from 'react-i18next';

import CATEGORIES from '@config/categories';

import * as styles from './styles.module.scss';

const CategoryTag: React.FunctionComponent = ({ category }) => {
    const { t } = useTranslation();
    return (
        <span
            className={styles['category_tag']}
            style={{
                backgroundColor: CATEGORIES[category]?.color,
                letterSpacing: category === 'leoandlea' ? -1.5 : 0,
            }}
        >
            {t(`category.${category}`)}
        </span>
    );
};

export default CategoryTag;
