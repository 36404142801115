import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, StaticQuery } from 'gatsby';

import Card from '@components/cards/new';
import CategoryTag from '@components/tags/category';
import TrackingLink from '@components/trackingLink';
import Image from '@components/imgComp';

import CATEGORIES from '@config/categories';

import * as styles from './styles.module.scss';

const CategoryBanner: FC<any> = ({ uid = 'homepage', isAmp = false }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['category__banner']}>
            <p>{t('new_posts.banner')}</p>
            <StaticQuery
                query={graphql`
                    query CategoryBannerQuery {
                        allPrismicBanners {
                            edges {
                                node {
                                    data {
                                        category_banner {
                                            url
                                            dimensions {
                                                width
                                                height
                                            }
                                            ...ImageFields
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={({ allPrismicBanners }) => {
                    const { category_banner } = allPrismicBanners?.edges[0]?.node?.data;
                    if (!category_banner) return null;
                    return (
                        <a
                            href={`https://start.leoandlea.com/v4?utm_source=wantimes&utm_medium=blog&utm_campaign=${uid}-cb`}
                            aria-label="Category Banner Link"
                        >
                            {isAmp ? (
                                <amp-img
                                    height={category_banner?.dimensions?.height}
                                    width={category_banner?.dimensions?.width}
                                    src={category_banner?.url}
                                    alt={category_banner?.alt}
                                    layout="responsive"
                                />
                            ) : (
                                <div className={styles['image']}>
                                    <Image
                                        style={{ width: '100%', height: '100%', minHeight: 135 }}
                                        {...category_banner}
                                    />
                                </div>
                            )}
                        </a>
                    );
                }}
            />
        </div>
    );
};

const CategoryCard: FC<any> = ({ category = '', isAmp = false, data }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['categories']}>
            <div className={styles['category__header']}>
                <CategoryTag category={category} />
                <p>
                    {t(`new_posts.${category}`)}
                    <TrackingLink
                        style={{
                            color: CATEGORIES[category].color,
                        }}
                        href={`/category/${category}/${isAmp ? 'amp/' : ''}`}
                        tracking={{ event: 'Category Clicked', obj: { category_name: category } }}
                    >
                        &nbsp;&gt; ALL
                    </TrackingLink>
                </p>
            </div>
            <div className={styles['category__cards']}>
                {/* filter by category and then take first 2 from array (most recent) */}
                {data?.map(({ data: cardData, uid }) => (
                    <Card
                        tracking={{
                            event: 'Category Article Clicked',
                            obj: {
                                article_category: cardData?.category,
                                article_name: cardData?.title.text,
                                uid,
                            },
                        }}
                        className={styles['category__card']}
                        key={uid}
                        isAmp={isAmp}
                        {...cardData}
                        uid={uid}
                    />
                ))}
            </div>
        </div>
    );
};

const NewPosts: FC<any> = ({ category, isAmp = false, content }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['new_posts']}>
            <span>{t('new_posts.title')}</span>
            <div className={styles['category_wrapper']}>
                {content?.new &&
                    Object.keys(content?.new).map((i) => (
                        <CategoryCard key={i} category={i} isAmp={isAmp} data={content.new[i]} />
                    ))}
                <CategoryBanner uid={category} isAmp={isAmp} />
            </div>
        </div>
    );
};

export default NewPosts;
